import PropTypes from 'prop-types';
import { Ic } from '@/components/basic/index';
const ErrorMessage = ({ iconStyle, messageStyle, message }) => {
  return (
    <span className="portal-error-message">
      <span style={iconStyle}>
        <Ic iName="faCircleExclamation" />
      </span>
      <span style={messageStyle}>{message}</span>
    </span>
  );
};

ErrorMessage.propTypes = {
  iconStyle: PropTypes.object,
  messageStyle: PropTypes.object,
  message: PropTypes.any,
};

export default ErrorMessage;
