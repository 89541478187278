import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, Col } from 'react-bootstrap';
import { getResponderList } from '@/api';
import { DateFormat } from '@/components/shared/index';
import Student from '@/entity/Student';
import Layout from '@/layout/Layout';
import style from './Students.scss';
import { Btn, Ic } from '@/components/basic/index';
import _ from 'lodash';

const Students = () => {
  const [students, setStudents] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const responders = await getResponderList();
      setStudents(
        responders.map((r) => {
          if (r.student.reports.length !== 0) {
            r.student.report = _.orderBy(r.student.reports, 'id', 'desc')[0];
          }
          return new Student(r.student);
        })
      );
    })();
  }, []);

  const toKarte = (id) => {
    history.push(`/student/${id}/karte`);
  };
  const toReport = (id) => {
    history.push(`/student/${id}/report`);
  };

  return (
    <>
      <Layout>
        <div className={style.dataTableWrapper}>
          <div className={style.summary}>現在 {students.length} 人の生徒を指導中</div>
          <div>
            {students.map((s, i) => {
              return (
                <Card key={i} className={style.personalPanel}>
                  <Card.Title>
                    {s.familyName}
                    {s.firstName}
                  </Card.Title>
                  <Card.Subtitle className={style.subTitle}>
                    {s.family.familyName}
                    {s.family.firstName}
                  </Card.Subtitle>
                  <Card.Subtitle className={style.subTitle}>
                    <span className={style.iconWrapper}>
                      <Ic iName="faHouse" />
                    </span>
                    {s.family.prefecture}
                    {s.family.address1}
                    {s.family.address2}
                  </Card.Subtitle>
                  <Card.Subtitle className={style.subTitle}>
                    <span className={style.iconWrapper}>
                      <Ic iName="faPhone" />
                    </span>
                    {s.family.tel}
                    {s.family.mobile_tel && (
                      <span className={style.iconWrapper} style={{ paddingLeft: '1rem' }}>
                        <Ic iName="faMobileScreenButton" />
                        {s.family.mobile_tel}
                      </span>
                    )}
                  </Card.Subtitle>
                  <Card.Body style={{ paddingBottom: '0.5rem' }}>
                    <Btn
                      isCustom
                      className={style.reportBtn}
                      outline
                      prefixIcon="faClipboard"
                      click={() => toKarte(s.id)}
                    >
                      指導カルテ
                    </Btn>
                    <Col className={style.next}>
                      {s.report.nextDate ? (
                        <div>
                          次回の指導日は
                          <DateFormat date={s.report.nextDate} /> {s.report.nextHour}時{s.report.nextMinute}分～
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Btn
                      isCustom
                      className={style.reportBtn}
                      outline
                      prefixIcon="faFileLines"
                      click={() => toReport(s.id)}
                    >
                      報告書
                    </Btn>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Students;
