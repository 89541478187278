import { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Hls from 'hls.js';
import { getToken } from '@/util/authentication';
import style from './CoachingDocuments.scss';
import { getCoachingDocumentWithViewingRecord, saveViewing, me } from '@/api/index';
import { Toast } from '@/components/shared/index';

const Document = () => {
  const [recodingTime, setRecodingTime] = useState(0);
  const [controls, setControls] = useState({
    seekedCurrentTime: 0,
    viewed: false,
    beforeViewing: 0,
    maxTime: null,
    currentTime: 0,
    seeking: false,
    seekTime: null,
  });
  const video = useRef(null);
  const videoWrapper = useRef(null);
  const { id } = useParams();

  const onDurationchange = () => {
    setControls({
      ...controls,
      maxTime: Math.ceil(video.current.duration),
      paused: video.current.paused,
    });
  };
  const onSeeking = () => {
    setControls({ ...controls, seeking: true });
  };
  const onSeeked = (e) => {
    const current = e.target.currentTime;
    setControls({ ...controls, seeking: false, seekedCurrentTime: current });
  };
  const recoding = async () => {
    const current = Math.ceil(video.current.currentTime);
    if (!controls.viewed && current > controls.beforeViewing) {
      await saveViewing(id, {
        viewingTime: Math.ceil(video.current.currentTime),
        isWatchTillEnd: false,
      });
    }
  };
  const onTimeUpdate = async () => {
    if (controls.seeking) return;
    const current = Math.ceil(video.current.currentTime);
    setControls({ ...controls, currentTime: current });
    if (`${current}`.length >= 2 && `${current}`.slice(-1) === '0' && recodingTime !== current) {
      await recoding();
      setRecodingTime(current);
      setControls((preControls) => {
        return { ...preControls, beforeViewing: current };
      });
    }
    if (isNaN(video.current.duration)) return;
    if (video.current.currentTime < video.current.duration) return;
    if (!controls.viewed) {
      await saveViewing(id, {
        viewingTime: Math.ceil(video.current.currentTime),
        isWatchTillEnd: true,
      });
    }
    video.current.pause();
    setControls((preControls) => {
      return { ...preControls, paused: true };
    });
    setTimeout(() => {
      video.current.currentTime = 0;
      setControls((preControls) => {
        return { ...preControls, currentTime: 0, paused: true };
      });
    }, 1000);
  };
  const onError = (e) => {
    console.log(e);
  };
  useEffect(() => {
    if (controls.seekedCurrentTime === 0) return;
    if (controls.beforeViewing < controls.seekedCurrentTime) {
      setControls({ ...controls, currentTime: controls.beforeViewing });
      video.current.currentTime = controls.beforeViewing;
      video.current.pause();
      return;
    }
    setControls({ ...controls, currentTime: controls.seekedCurrentTime });
    video.current.currentTime = controls.seekedCurrentTime;
  }, [controls.seekedCurrentTime]);
  useEffect(() => {
    (async () => {
      const data = await getCoachingDocumentWithViewingRecord(id);
      if (!data) {
        Toast.error('動画がありません。', 1000 * 60 * 60);
        return;
      }
      let beforeViewing =
        data.teacherVideoViewingHistory.length !== 0 ? data.teacherVideoViewingHistory[0].viewingTime : 0;
      const isViewed =
        data.teacherVideoViewingHistory.length !== 0 ? data.teacherVideoViewingHistory[0].isWatchTillEnd : false;
      setControls({
        ...controls,
        beforeViewing: beforeViewing,
        viewed: isViewed,
      });

      const video = document.getElementById('video');
      const url = `${window.env.apiEndPoint}/api/v1/coachingDocuments/${id}/movie?t=${getToken()}`;
      if (Hls.isSupported()) {
        const hls = new Hls({
          autoStartLoad: true,
        });
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.startLoad();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
        video.addEventListener('loadedmetadata', function () {
          video.play();
        });
      }
      // トークンリフレッシュのためにタイマーで自身の情報を取得しとく
      setInterval(() => {
        me();
      }, 1000 * 60);
    })();
  }, []);
  return (
    <>
      <Toaster />
      <Container fluid className={style.movieWrapper}>
        <div id="videoWrapper" data-tap-disabled="true" className={`video-player`} ref={videoWrapper}>
          <>
            <video
              id="video"
              ref={video}
              playsInline
              controlsList="nodownload"
              onContextMenu={() => {
                return false;
              }}
              onError={onError}
              onSeeking={onSeeking}
              onSeeked={onSeeked}
              controls
              onTimeUpdate={onTimeUpdate}
              onDurationChange={onDurationchange}
              className="portal-video"
            />
          </>
        </div>
      </Container>
    </>
  );
};

export default Document;
