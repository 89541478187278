class Family {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.familyNameKana = param.familyNameKana || '';
    this.firstNameKana = param.firstNameKana || '';
    this.tel = param.tel || '';
    this.mobileTel = param.mobileTel || '';
    this.prefecture = param.prefecture || '';
    this.address1 = param.address1 || '';
    this.address2 = param.address2 || '';
  }

  fullName() {
    return `${this.familyName} ${this.firstName}`;
  }
  fullNameKana() {
    return `${this.familyNameKana} ${this.firstNameKana}`;
  }
  address() {
    return `${this.prefecture} ${this.address1} ${this.address2}`;
  }
}

export default Family;
