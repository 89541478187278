import _ from 'lodash';
import { File } from './File';

class Teacher {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.familyNameKana = param.familyNameKana || '';
    this.firstNameKana = param.firstNameKana || '';
    this.gender = param.gender || '';
    this.birthDate = param.birthDate;
    this.age = param.age;
    this.zip1 = param.zip1 || '';
    this.zip2 = param.zip2 || '';
    this.prefecture = param.prefecture || '';
    this.address1 = param.address1 || '';
    this.address2 = param.address2 || '';
    this.tel = param.tel || '';
    this.mail = param.mail || '';
    this.mobileTel = param.mobileTel || '';
    this.university = param.university || '';
    this.major = param.major || '';
    this.schoolYear = param.schoolYear || '';
    this.station = param.station || '';
    this.highSchool = param.highSchool || '';
    this.zip1Family = param.zip1Family || '';
    this.zip2Family = param.zip2Family || '';
    this.prefectureFamily = param.prefectureFamily || '';
    this.address1Family = param.address1Family || '';
    this.address2Family = param.address2Family || '';
    this.telFamily = param.telFamily || '';
    this.examExperience = param.examExperience || '';
    this.teachExperience = param.teachExperience || '';
    this.allergy = param.allergy || '';
    this.teachDays = param.teachDays || '';

    this.teacherFiles = (param.teacherFiles || []).map((f) => {
      return new File({
        id: f.fileId,
        attribute: f.attribute,
      });
    });
    this.tmpFiles = param.tmpFiles || [];
  }

  fullName() {
    return `${this.familyName} ${this.firstName}`;
  }

  fullNameKana() {
    return `${this.familyNameKana} ${this.firstNameKana}`;
  }

  zip() {
    return this.zip1 === '' ? '' : `${this.zip1}-${this.zip2}`;
  }

  fullAddress() {
    return `${this.zip()} ${this.prefecture} ${this.address1} ${this.address2}`;
  }

  zipFamily() {
    return this.zip1Family === '' ? '' : `${this.zip1Family}-${this.zip2Family}`;
  }

  fullAddressFamily() {
    return `${this.zipFamily()} ${this.prefectureFamily} ${this.address1Family} ${this.address2Family}`;
  }

  profileFile() {
    return _.find(this.teacherFiles, { attribute: 'profile' });
  }

  resumeFile() {
    return _.find(this.teacherFiles, { attribute: 'resume' });
  }

  forApi() {
    return {
      familyName: this.familyName,
      firstName: this.firstName,
      familyNameKana: this.familyNameKana,
      firstNameKana: this.firstNameKana,
      gender: this.gender,
      birthDate: this.birthDate,
      age: this.age,
      zip1: this.zip1,
      zip2: this.zip2,
      prefecture: this.prefecture,
      address1: this.address1,
      address2: this.address2,
      tel: this.tel,
      mobileTel: this.mobileTel,
      university: this.university,
      major: this.major,
      schoolYear: this.schoolYear,
      station: this.station,
      highSchool: this.highSchool,
      zip1Family: this.zip1Family,
      zip2Family: this.zip2Family,
      prefectureFamily: this.prefectureFamily,
      address1Family: this.address1Family,
      address2Family: this.address2Family,
      telFamily: this.telFamily,
      examExperience: this.examExperience,
      teachExperience: this.teachExperience,
      allergy: this.allergy,
      teachDays: this.teachDays,
      tmpFileIds: this.tmpFiles.map((f) => {
        return f.id;
      }),
    };
  }
}

export default Teacher;
