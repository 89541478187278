import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { forgotPassword } from '@/api/index';
import { Container, Form, Row, Col } from 'react-bootstrap';
import Layout from '@/layout/Layout';
import { FItemGroup } from '@/components/shared/index';
import { FTxt, Btn } from '@/components/basic/index';
import style from './Login.scss';

const { company } = window.env;

const Password = () => {
  const history = useHistory();
  const [mail, setMail] = useState('');
  const [send, setSend] = useState(false);

  const sendPasswordMail = async () => {
    await forgotPassword({ mail });
    setSend(true);
  };

  const toLogin = () => {
    history.push('/login');
  };

  return (
    <Layout>
      <Container fluid className={style.passwordWrapper}>
        <div className={style.contents}>
          <div>
            {!send && (
              <div className={style.form}>
                <div className={style.description}>
                  <div>{company.shortBrandName}へお届けのメールアドレスを入力してください。</div>
                  <div>
                    その後パスワードリセットの案内メールが送られますので、メールの内容に従って操作をしてください。
                  </div>
                </div>
                <Form noValidate>
                  <FItemGroup label="メールアドレス" labelFontColor="#000">
                    <FTxt error="" value={mail} change={(e) => setMail(e.target.value)} />
                  </FItemGroup>
                </Form>
                <Row className={style.footer}>
                  <Col>
                    <Btn click={sendPasswordMail} prefixIcon="faPaperPlane">
                      メール送信
                    </Btn>
                  </Col>
                </Row>
              </div>
            )}
            {send && (
              <>
                <div>パスワード設定案内をメール送信しました。</div>
                <div>しばらく経ってもメールが届かない場合は、再度操作をしてください。</div>
              </>
            )}
            <div className={style.toLogin}>
              ログイン画面に<a onClick={toLogin}>戻る</a>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Password;
