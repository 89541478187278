import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Btn } from '@/components/basic/index';
const ConfirmModal = ({
  title,
  show = false,
  content,
  footerButtonTitle,
  onClose = () => {},
  onSubmit = () => {},
  showSubmitLoading,
}) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Btn click={onSubmit} showLoading={showSubmitLoading}>
          {footerButtonTitle}
        </Btn>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  footerButtonTitle: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  content: PropTypes.string,
  showSubmitLoading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  showSubmitLodaing: false,
};
export default ConfirmModal;
