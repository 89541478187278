/* eslint-disable no-useless-escape */
import { isEmpty } from './util';
import ld from 'lodash';

export const get = (from, ...selectors) => {
  return [...selectors].map((s) => {
    if (!s.replace) return undefined;
    return s
      .replace(/\[([^\[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t) => t !== '')
      .reduce((prev, cur) => prev && prev[cur], from);
  });
};
const checkFunc = {
  require: (value, label, rule) => {
    const inValid = isEmpty(value);
    const msg = rule.type === 'text' ? '入力してください' : '選択してください';
    return { inValid, msg: inValid ? `${label}を${msg}。` : undefined };
  },
  isNumeric: (value, label) => {
    const inValid = !/^([1-9]\d*|0)$/.test(String(value));
    return { inValid, msg: inValid ? `${label}は数値で入力してください。` : undefined };
  },
  isNumRange: (value, label, rule) => {
    const inValid = value > rule.max || value < rule.min;
    return { inValid, msg: inValid ? `${label}は有効な範囲ではありません。` : undefined };
  },
  isCustom: (value, _, rule) => {
    const inValid = rule.customFunc(value);
    return { inValid, msg: inValid ? rule.message : undefined };
  },
};
export const Rule = {
  require: (type = 'text') => {
    return { key: 'require', type: type, execOrder: 1 };
  },
  num: () => {
    return {
      key: 'isNumeric',
      execOrder: 2,
    };
  },
  numRange: (min, max) => {
    return {
      key: 'isNumRange',
      execOrder: 3,
      min: min,
      max: max,
    };
  },
  custom: (func, message) => {
    return {
      key: 'isCustom',
      execOrder: 4,
      customFunc: func,
      message: message,
    };
  },
};
export class Validator {
  constructor(model) {
    this.model = model;
    this.errors = [];
  }
  exec(path, label, rulesAry) {
    const [v] = get(this.model, path);
    for (const rule of ld.sortBy(rulesAry, 'execOrder')) {
      const rs = checkFunc[rule.key](v, label, rule);
      if (rs.inValid) {
        this.errors.push({ key: path, message: rs.msg });
        break;
      }
    }
  }
  inValid() {
    return this.errors.length >= 1;
  }
  getErrors() {
    const rs = {};
    this.errors.forEach((r) => {
      rs[`${r.key}`] = r.message;
    });
    return rs;
  }
}
