import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import cssStyle from './Shared.scss';
import { ErrMsg } from './index';

const FormItemGroup = ({
  label,
  labels = [],
  className,
  children,
  separate,
  full,
  labelFontColor,
  style,
  errors,
  paths,
}) => {
  const labelSeparation = label ? [{ label, size: 12 }] : labels;

  const errorMessages = () => {
    if (paths.length === 0) return <></>;
    const errorMessages = paths
      .map((r) => {
        const error = errors[r];
        if (!error) return undefined;
        return error;
      })
      .filter(Boolean);
    if (errorMessages.length === 0) return <></>;
    return (
      <Row>
        <Col>
          {errorMessages.map((r, i) => (
            <React.Fragment key={i}>
              <ErrMsg iconStyle={{ marginLeft: '0.5rem', marginRight: '0.25rem' }} message={r} />
            </React.Fragment>
          ))}
        </Col>
      </Row>
    );
  };
  const Label = (v) => {
    return (v || '').split('\n').map((r, i) => {
      return (
        <React.Fragment key={i}>
          {r} <br />
        </React.Fragment>
      );
    });
  };
  return (
    <Form.Group style={style} className={className}>
      <Row>
        {labelSeparation.map((l, i) => {
          return (
            <React.Fragment key={i}>
              <Col xs={l.size}>
                <Form.Label className={cssStyle.formLabel} style={{ color: labelFontColor }}>
                  {Label(l.label)}
                </Form.Label>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
      {full ? (
        <Row>
          <Col>{children}</Col>
        </Row>
      ) : (
        <Row>{children}</Row>
      )}
      {errorMessages()}
      {separate ? <div className={cssStyle.bottomBorder} /> : <></>}
    </Form.Group>
  );
};

FormItemGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  labelFontColor: PropTypes.string,
  full: PropTypes.bool,
  last: PropTypes.bool,
  separate: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  paths: PropTypes.array,
  errors: PropTypes.object,
};

FormItemGroup.defaultProps = {
  separate: false,
  full: true,
  labelFontColor: '#7a7a7a',
  paths: [],
  errors: {},
};
export default FormItemGroup;
