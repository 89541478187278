import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ show = false, file, title, onClose = () => {} }) => {
  return (
    <Modal show={show} fullscreen onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        {file ? (
          <img
            src={file}
            alt="ExamOrRecordImage"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

ImageModal.propTypes = {
  show: PropTypes.bool,
  file: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default ImageModal;
