import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormNote = ({ size, rows, disabled, className, style, value, error, change }) => {
  const classNames = [basicStyle.glText, className];
  const inValid = error !== null && error !== undefined && error !== '';
  return (
    <InputGroup size={size}>
      <Form.Control
        as="textarea"
        rows={rows}
        className={classNames.filter(Boolean).join(' ')}
        disabled={disabled}
        value={value}
        size={size}
        style={style}
        onChange={change}
        isInvalid={inValid}
      />
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </InputGroup>
  );
};

FormNote.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  change: PropTypes.func,
  error: PropTypes.string,
};

FormNote.defaultProps = {
  size: 'sm',
  rows: 5,
  disabled: false,
  change: () => {},
  error: null,
};

export default FormNote;
