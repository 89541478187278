export class File {
  constructor(param = {}) {
    this.id = param.id || null;
    this.fileName = param.fileName || '';
    this.mime = param.mime || '';
    this.attribute = param.attribute || '';
  }
}

export class TemporaryFile {
  constructor(param = {}) {
    this.id = param.id || null;
    this.fileName = param.fileName || '';
    this.mime = param.mime || '';
    this.attribute = param.attribute || '';
    this.data = param.data || '';
  }
  dataForIMG() {
    if (this.data.startsWith('data:')) {
      return this.data;
    } else {
      return `data:${this.mime};base64,${this.data}`;
    }
  }
}

export default TemporaryFile;
