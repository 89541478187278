import lscache from 'lscache';

const storageExpireSeconds = 24 * 60 * 60;

export const getFromStorage = (key, defaultValue = '', expire = storageExpireSeconds) => {
  lscache.flushExpired();
  const storedValue = lscache.get(key);
  if (storedValue != null) {
    return storedValue;
  }

  storeToStorage(key, defaultValue, expire);
  return defaultValue;
};

export const storeToStorage = (key, val, expire = storageExpireSeconds) => {
  lscache.flushExpired();
  lscache.set(key, val, expire);
};

export const removeFromStorage = (key) => {
  lscache.flushExpired();
  lscache.remove(key);
};
