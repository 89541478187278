import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormRadio = ({
  gName,
  options,
  size,
  disabled,
  value,
  className,
  style,
  valueField,
  labelField,
  error,
  change,
}) => {
  const classNames = ['cst-select', basicStyle.glRadio, className];
  const inValid = error !== null && error !== undefined && error !== '';

  return (
    <>
      {options.map((r) => {
        return (
          <React.Fragment key={r[valueField]}>
            <InputGroup size={size}>
              <Form.Check
                className={classNames}
                style={style}
                disabled={disabled}
                value={r.value}
                label={r[labelField]}
                name={gName}
                type="radio"
                id={`${gName}-${r[valueField]}`}
                onChange={change}
                checked={r.value === value}
              />
            </InputGroup>
          </React.Fragment>
        );
      })}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </>
  );
};

FormRadio.propTypes = {
  gName: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.func,
  error: PropTypes.string,
};

FormRadio.defaultProps = {
  size: 'sm',
  disabled: false,
  valueField: 'value',
  labelField: 'label',
  change: () => {},
  error: null,
};

export default FormRadio;
