import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Toaster } from 'react-hot-toast';
import style from './layout.scss';
import { logout } from '@/util/authentication';
import { isNoNeedLogins } from '@/routes/index';
import { me } from '@/api/index';
import { Btn } from '@/components/basic/index';

const { company, functions } = window.env;

const Layout = ({ children }) => {
  const history = useHistory();
  const [iam, setIam] = useState(null);

  useEffect(() => {
    (async () => {
      if (isNoNeedLogins(history.location.pathname)) {
        return;
      }
      const iam = await me();
      if (!iam) {
        doLogout();
      }
      setIam(iam);
    })();
  }, []);

  const doLogout = (e) => {
    logout();
    document.location.href = '/login';
  };

  const moveTo = (to) => {
    history.push(to);
  };

  return (
    <>
      <Toaster />
      <Navbar className={`me-auto ${style.topBar}`}>
        <Nav className="me-auto">
          <NavItem className={style.brand}>{company.brandName}</NavItem>
        </Nav>
        <Nav className="justify-content-end">
          <div className={`btn-group ${style.menuPanel}`}>
            {iam && (
              <>
                <Btn
                  isCustom={true}
                  className={style.dropdownBtn}
                  width="35px"
                  height="35px"
                  dataToggle="dropdown"
                  ariaHaspopup="true"
                  ariaExpanded="false"
                  circle
                  prefixIcon="faBars"
                />
                <div className={`dropdown-menu dropdown-menu-right ${style.dropDownPanel}`}>
                  {functions.includes('student') && (
                    <>
                      <button className="dropdown-item" type="button" onClick={() => moveTo('/student')}>
                        担当生徒
                      </button>
                    </>
                  )}
                  {functions.includes('matter') && (
                    <>
                      <button className="dropdown-item" type="button" onClick={() => moveTo('/matter')}>
                        案件情報
                      </button>
                    </>
                  )}
                  {functions.includes('profile') && (
                    <>
                      <button className="dropdown-item" type="button" onClick={() => moveTo('/profile')}>
                        個人設定
                      </button>
                    </>
                  )}
                  {functions.includes('coaching-document') && (
                    <>
                      <button className="dropdown-item" type="button" onClick={() => moveTo('/coaching-document')}>
                        指導資料
                      </button>
                    </>
                  )}
                  <button className="dropdown-item" type="button" onClick={doLogout}>
                    ログアウト
                  </button>
                </div>
              </>
            )}
          </div>
        </Nav>
      </Navbar>
      <section className={style.pageContainer}>
        <main>{children}</main>
      </section>
    </>
  );
};

export default Layout;
