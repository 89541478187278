import { toCamelCase, apiBase } from './ApiBase';
import TemporaryFile from '@/entity/File';

export const uploadTemporaryFile = async ({ file }) => {
  const { data } = await apiBase.post(`/api/v1/tmpFile`, { file });
  return new TemporaryFile(toCamelCase(data));
};

export const deleteTemporaryFile = async ({ file }) => {
  const { data } = await apiBase.delete(`/api/v1/tmpFile/${file.id}`);
  return new TemporaryFile(toCamelCase(data));
};

export const getFileContent = async ({ id }) => {
  const { data } = await apiBase.get(`/api/v1/file/${id}`, {}, { responseType: 'blob' });
  return data;
};
