import camelcaseKeys from 'camelcase-keys';
import axios from 'axios';
import { storeToStorage } from '@/store/storage';
import { tokenKey } from '@/constants/index';

const { apiEndPoint, axiosWithCredentials } = window.env;

export const toCamelCase = (data) => (data === '' ? undefined : camelcaseKeys(data, { deep: true }));

class ApiBase {
  constructor() {
    this.api = axios.create({
      baseURL: apiEndPoint,
      headers: {
        common: {
          Accept: 'application/json',
        },
        patch: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      withCredentials: axiosWithCredentials,
    });
    this.bareApi = axios.create({ baseURL: '' });
    this.apiNoAuth = axios.create({ baseURL: apiEndPoint });
    this.dlToken = null;
    this.token = null;
    this.api.interceptors.request.use((config) => {
      config.headers['Content-Type'] =
        config.data instanceof FormData ? 'multipart/form-data' : 'application/json;charset=utf-8';
      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }
      return config;
    });
    this.bareApi.interceptors.request.use((config) => {
      config.headers['Content-Type'] =
        config.data instanceof FormData ? 'multipart/form-data' : 'application/json;charset=utf-8';
      if (this.dlToken) {
        config.headers.Authorization = `Bearer ${this.dlToken}`;
      }
      return config;
    });
    this.api.interceptors.response.use((response) => {
      if (response.headers['authorization']) {
        this.token = response.headers['authorization'];
        storeToStorage(tokenKey, this.token);
      }
      return response;
    }, this._errorResponseHandler);
    this.apiNoAuth.interceptors.response.use((response) => {
      return response;
    }, this._errorResponseHandlerForNoAuth);
    this.getUserName = null;
  }

  /**
   * URLを指定してGETする
   */
  bareGet(url, params, config) {
    return this._getInner(this.bareApi, url, params, config);
  }

  /**
   * 認証トークンを必要としないGetメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @param {*} config
   * @returns AxiosResponse
   */
  noAuthGet(url, params, config) {
    return this._getInner(this.apiNoAuth, url, params, config);
  }

  /**
   * 認証トークンを必要としないPostメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  noAuthPost(url, params) {
    return this._postInner(this.apiNoAuth, url, params);
  }

  /**
   * 認証トークンを必要としないPutメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  noAuthPut(url, params) {
    return this._putInner(this.apiNoAuth, url, params);
  }

  /**
   * 認証トークンを必要としないDeleteメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  noAuthDelete(url, params) {
    return this._deleteInner(this.apiNoAuth, url, params);
  }

  /**
   * 認証トークンが必要なGetメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @param {*} config
   * @returns AxiosResponse
   */
  async get(url, params = {}, config = {}) {
    return this._getInner(this.api, url, params, config);
  }

  /**
   * 認証トークンが必要なPostメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  post(url, params) {
    return this._postInner(this.api, url, params);
  }

  /**
   * 認証トークンが必要なPutメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  put(url, params) {
    return this._putInner(this.api, url, params);
  }

  /**
   * 認証トークンが必要なDeleteメソッドのapiを実行します
   * @param {*} url URL
   * @param {*} params パラメータオブジェクト
   * @returns AxiosResponse
   */
  delete(url, params = {}) {
    return this._deleteInner(this.api, url, params);
  }

  /**
   * [Private処理] AxiosのGetを実行する
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @returns AxiosResponse
   */
  _getInner(_api, _url, _params, config) {
    return _params ? _api.get(_url, { params: _params, ...config }) : _api.get(_url, { ...config });
  }

  /**
   * [Private処理] AxiosのPostを実行する
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @returns AxiosResponse
   */
  _postInner(_api, _url, _params) {
    return _params ? _api.post(_url, _params) : _api.post(_url);
  }

  /**
   * [Private処理] AxiosのPutを実行する
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @returns AxiosResponse
   */
  _putInner(_api, _url, _params) {
    return _params ? _api.put(_url, _params) : _api.put(_url);
  }

  /**
   * [Private処理] AxiosのDeleteを実行する
   * @param {*} url URL
   * @param {*} params Queryパラメータオブジェクト
   * @returns AxiosResponse
   */
  _deleteInner(_api, _url, _params) {
    return _params ? _api.delete(_url, { data: _params }) : _api.delete(_url);
  }

  /**
   * [Private処理] Axiosのエラーレスポンスインターセプト処理
   * @param {*} error AxiosError
   * @returns Error
   */
  _errorResponseHandler(error) {
    return Promise.reject(error);
  }

  /**
   * [Private処理] Axiosのエラーレスポンスインターセプト処理
   * @param {*} error AxiosError
   * @returns Error
   */
  _errorResponseHandlerForNoAuth(error) {
    return Promise.reject(error);
  }
}

export const apiBase = new ApiBase();
