import Family from './Family';

class Student {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.familyNameKana = param.familyNameKana || '';
    this.firstNameKana = param.firstNameKana || '';
    this.gender = param.gender || '';
    this.family = new Family(param.family || {});
    this.report = param.report ? param.report : {};
  }

  fullName() {
    return `${this.familyName} ${this.firstName}`;
  }
  fullNameKana() {
    return `${this.familyNameKana} ${this.firstNameKana}`;
  }
}

export default Student;
