import toast from 'react-hot-toast';

class Toast {
  constructor() {
    this.toastId = null;
  }
  success(message, duration = 5000) {
    this.toastId = toast.success(message, {
      duration: duration,
      className: 'success-toast',
    });
  }
  error(message, duration = 5000) {
    this.toastId = toast.error(message, {
      duration: duration,
      className: 'error-toast',
    });
  }
  warn(message, duration = 5000) {
    this.toastId = toast(message, {
      duration: duration,
      position: 'top-center',
      icon: '⚠',
      className: 'warn-toast',
      iconTheme: {
        primary: '#ffc107',
        secondary: '#fff',
      },
    });
  }
  clear() {
    toast.remove();
  }
}
const myToast = new Toast();

export default myToast;
