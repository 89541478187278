import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '@/layout/Layout';
import { resetPassword } from '@/api/index';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { FItemGroup } from '@/components/shared/index';
import { FPass, Btn } from '@/components/basic/index';
import style from './Login.scss';

const Reset = (props) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const resetToken = props.location.state.resetToken;

  const doReset = async () => {
    await resetPassword({ resetToken, password });
    history.push('/login');
  };

  const canDoReset = () => {
    return !(password.length >= 8 && password === confirm);
  };

  return (
    <Layout>
      <Container fluid className={style.passwordWrapper}>
        <div className={style.contents}>
          <div>
            <div className={style.form}>
              <div className={style.description}>
                <div>パスワードをリセットします。</div>
              </div>
              <Form noValidate>
                <FItemGroup label="新しいパスワード">
                  <FPass error="" value={password} change={(e) => setPassword(e.target.value)} />
                </FItemGroup>
                <FItemGroup label="再入力">
                  <FPass error="" value={confirm} change={(e) => setConfirm(e.target.value)} />
                </FItemGroup>
              </Form>
              <Row className={style.footer}>
                <Col>
                  <Btn disabled={canDoReset()} click={doReset} prefixIcon="faRotateRight">
                    リセット
                  </Btn>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Reset;
