import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createStore from '@/store/Store';
import { restoreToken } from '@/util/authentication';
import './index.scss';

const history = createBrowserHistory();
const store = createStore(history);
const { company } = window.env;

restoreToken();

const render = () => {
  document.title = company.brandName;
  return ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history} />
        </ConnectedRouter>
      </Provider>
    </React.Fragment>,
    document.getElementById('root')
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
render();
