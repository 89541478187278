import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Layout from '@/layout/Layout';
import { getKarte } from '@/api/index';
import * as React from 'react';
import { DItemGroup, Section } from '@/components/shared/index';
import { Btn, Ic } from '@/components/basic/index';
import style from './Students.scss';

const Karte = () => {
  const history = useHistory();
  const { id } = useParams();
  const [karte, setKarte] = useState(null);
  const [firstStudent, setFirstStudent] = useState(null);

  useEffect(() => {
    (async () => {
      const karte = await getKarte({ studentId: id });
      setKarte(karte);
      setFirstStudent(karte.students[0]);
    })();
  }, []);

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.karteWrapper}>
          {karte && firstStudent && (
            <>
              <Section title="基本情報" first>
                <DItemGroup label="お申込者">
                  <div>
                    {karte.familyNameKana}
                    {karte.firstNameKana}
                  </div>
                  <div>
                    {karte.familyName}
                    {karte.firstName}
                  </div>
                </DItemGroup>
                <DItemGroup label="住所">
                  {karte.zip1 && (
                    <div>
                      〒{karte.zip1}-{karte.zip2}
                    </div>
                  )}
                  <div>
                    {karte.prefecture}
                    {karte.address1}
                    {karte.address2}
                  </div>
                </DItemGroup>
                <DItemGroup label="TEL">{karte.tel}</DItemGroup>
                <DItemGroup label="携帯">{karte.mobileTel}</DItemGroup>
                <DItemGroup label="駐車">{karte.park}</DItemGroup>
                <DItemGroup label="交通" isLast>
                  <div>
                    <span className={style.iconWrapper}>
                      <Ic iName="faTrain" />
                    </span>
                    <span>
                      {karte.station}
                      {karte.stationDistance}
                      {karte.stationTime}
                    </span>
                  </div>
                  <div>
                    <span className={style.iconWrapper}>
                      <Ic iName="faBus" />
                    </span>
                    <span>
                      {karte.bus}
                      {karte.busDistance}
                      {karte.busTime}
                    </span>
                  </div>
                </DItemGroup>
              </Section>
              {karte.students.map((s, i) => {
                return (
                  <React.Fragment key={i}>
                    <Section title={`生徒${i + 1}`}>
                      <DItemGroup label="姓名">
                        <div>
                          {s.familyNameKana}
                          {s.firstNameKana}
                        </div>
                        <div>
                          {s.familyName}
                          {s.firstName}
                        </div>
                      </DItemGroup>
                      <DItemGroup label="性別">{s.gender}</DItemGroup>
                      <DItemGroup label="学校">
                        {s.schoolCategory}
                        {s.schoolName}
                        {s.schoolKind}
                      </DItemGroup>
                      <DItemGroup label="学年">{s.grade}</DItemGroup>
                      <DItemGroup label="生年月日">{s.birthDate}</DItemGroup>
                      <DItemGroup label={'前学期\r\n成績'}>
                        <Row>
                          <Col xs={8}>
                            <table className={style.scoreTable}>
                              <thead>
                                <tr>
                                  <th>英</th>
                                  <th>数</th>
                                  <th>国</th>
                                  <th>理</th>
                                  <th>社</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{s.scoreEnglish ?? 0}</td>
                                  <td>{s.scoreMath ?? 0}</td>
                                  <td>{s.scoreJapanese ?? 0}</td>
                                  <td>{s.scoreScience ?? 0}</td>
                                  <td>{s.scoreSocial ?? 0}</td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col style={{ paddingLeft: 0 }}>
                            <table className={style.scoreTable}>
                              <thead>
                                <tr>
                                  <th>TOTAL</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {s.scoreEnglish + s.scoreMath + s.scoreJapanese + s.scoreScience + s.scoreSocial}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </DItemGroup>
                      <DItemGroup label="得意科目">{s.strongSubject}</DItemGroup>
                      <DItemGroup label="苦手科目">{s.weakSubject}</DItemGroup>
                      <DItemGroup label="習い事">
                        {s.lessons}
                        {s.lessonsDay}
                        {s.lessonsTime}
                      </DItemGroup>
                      <DItemGroup label={'志望校'}>{s.desireSchool}</DItemGroup>
                      <DItemGroup label={'部活動'}>
                        {s.club}
                        {s.clubDay}
                      </DItemGroup>
                      <DItemGroup label={'帰宅時間'}>{s.homeTime}</DItemGroup>
                      <DItemGroup label={'趣味'}>{s.hobby}</DItemGroup>
                      <DItemGroup label={'指導期間'}>
                        {s.startPlan}〜{s.endPlan}
                      </DItemGroup>
                      <DItemGroup label={'契約更新\r\n時期'}>{s.takeOver}</DItemGroup>
                      <DItemGroup label={'伝達'} isLast>
                        {s.contractRemarks}
                      </DItemGroup>
                    </Section>
                  </React.Fragment>
                );
              })}
              <Section title="特記事項">
                <DItemGroup label="ペット">
                  {karte.pet_in_room}
                  {karte.pet}
                </DItemGroup>
                <DItemGroup label="メモ" isLast>
                  {karte.karuteNotice}
                </DItemGroup>
              </Section>
              <Section title="指導情報">
                <DItemGroup label={'回数'}>週{firstStudent.teachAmount}回</DItemGroup>
                <DItemGroup label={'コース'}>{firstStudent.teachTime}分コース</DItemGroup>
                <DItemGroup label={'テキスト\r\n記入'}>{firstStudent.textRecord}</DItemGroup>
                <DItemGroup label={'曜日'}>
                  {firstStudent.teachMonday && <span className={style.weekend}>月</span>}
                  {firstStudent.teachTuesday && <span className={style.weekend}>火</span>}
                  {firstStudent.teachWednesday && <span className={style.weekend}>水</span>}
                  {firstStudent.teachThursday && <span className={style.weekend}>木</span>}
                  {firstStudent.teachFriday && <span className={style.weekend}>金</span>}
                  {firstStudent.teachSaturday && <span className={style.weekend}>土</span>}
                  {firstStudent.teachSunday && <span className={style.weekend}>日</span>}
                </DItemGroup>
                <DItemGroup label={'時間'}>
                  <div>
                    <span className={style.schedule}>平日</span>
                    {firstStudent.teachKaruteWeekdayTimeband}
                  </div>
                  <div>
                    <span className={style.schedule}>土曜</span>
                    {firstStudent.teachKaruteSaturdayTimeband}
                  </div>
                  <div>
                    <span className={style.schedule}>日曜</span>
                    {firstStudent.teachKaruteSundayTimeband}
                  </div>
                  <div>
                    <span className={style.schedule}>その他</span>({firstStudent.teachKaruteOtherday})
                    {firstStudent.teachKaruteOtherdayTimeband}
                  </div>
                </DItemGroup>
                <DItemGroup label={'時給'}>{karte.charge}</DItemGroup>
                {/*<DItemGroup title={'1回あたりの指導料'}></DItemGroup>*/}
                {/*<DItemGroup title={'開始予定'}></DItemGroup>*/}
                {/*<DItemGroup title={'性別'}>{f.teachSex}</DItemGroup>*/}
                {/*<DItemGroup title={'年齢'}></DItemGroup>*/}
                {/*<DItemGroup title={'学歴'}></DItemGroup>*/}
                <DItemGroup label={'交通費'}>{firstStudent.teachTravelCostRemarks}</DItemGroup>
                <DItemGroup label={'送迎'}>{firstStudent.teachPickup}</DItemGroup>
                <DItemGroup label={'送迎場所'} isLast>
                  {firstStudent.teachPickupPlace}
                </DItemGroup>
                {/*<DItemGroup label={'指導方針、その他伝達事項'}></DItemGroup>*/}
              </Section>
            </>
          )}
          <Row className={style.footer}>
            <Col>
              <Btn
                outline
                type="primary"
                click={() => {
                  back();
                }}
                prefixIcon="faAnglesLeft"
              >
                戻る
              </Btn>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default Karte;
