import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Button as ReactBootStrapButton, Spinner } from 'react-bootstrap';
import Icon from './Icon';
import basicStyle from './Basic.scss';

const Button = ({
  type,
  size,
  isCustom,
  disabled,
  className,
  color,
  outline,
  circle,
  style,
  border,
  children,
  click,
  dataToggle,
  ariaHaspopup,
  ariaExpanded,
  prefixIcon,
  width,
  height,
  showLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const styles = { ...style };
  styles.width = width;
  styles.height = height;
  const iconStyle = children ? { marginRight: '0.5rem' } : undefined;

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const clickHandler = async () => {
    if (showLoading) setLoading(true);
    try {
      await click();
    } finally {
      setLoading(false);
    }
  };
  const LoadingSpinner = () => {
    return (
      <div className="my-loading-wrapper">
        <div className="my-loading-inner">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  };

  if (isCustom) {
    const classNames = [basicStyle.cstmBtn, circle ? basicStyle.circle : undefined, className];
    return (
      <>
        <button
          className={classNames.filter(Boolean).join(' ')}
          type={type}
          disabled={disabled}
          style={styles}
          data-toggle={dataToggle}
          aria-haspopup={ariaHaspopup}
          aria-expanded={ariaExpanded}
          onClick={clickHandler}
        >
          {prefixIcon ? (
            <span style={iconStyle}>
              <Icon iName={prefixIcon} />
            </span>
          ) : (
            <></>
          )}
          {children ? <span>{children}</span> : <></>}
        </button>
        {loading ? <LoadingSpinner /> : <></>}
      </>
    );
  }
  const classNames = [
    basicStyle.glBtn,
    circle ? basicStyle.circle : undefined,
    border ? undefined : basicStyle.noBorder,
    className,
  ];
  const variant = `${outline ? 'outline-' : ''}${color}`;

  return (
    <>
      <ReactBootStrapButton
        className={classNames.filter(Boolean).join(' ')}
        type={type}
        variant={variant}
        disabled={disabled}
        size={size}
        style={styles}
        onClick={clickHandler}
        data-toggle={dataToggle}
        aria-haspopup={ariaHaspopup}
        aria-expanded={ariaExpanded}
      >
        {prefixIcon ? (
          <span style={iconStyle}>
            <Icon iName={prefixIcon} />
          </span>
        ) : (
          <></>
        )}
        {children ? <span>{children}</span> : <></>}
      </ReactBootStrapButton>
      {loading ? <LoadingSpinner /> : <></>}
    </>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'link']),
  outline: PropTypes.bool,
  circle: PropTypes.bool,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
  click: PropTypes.func,
  isCustom: PropTypes.bool,
  dataToggle: PropTypes.string,
  ariaHaspopup: PropTypes.string,
  ariaExpanded: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  prefixIcon: PropTypes.string,
  showLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  size: 'sm',
  color: 'primary',
  isCustom: false,
  border: true,
  outline: false,
  disabled: false,
  circle: false,
  width: '100%',
  height: '40px',
  click: () => {},
  showLoading: false,
};

export default Button;
