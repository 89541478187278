class GuidanceMaterial {
  constructor(param = { teacherVideoViewingHistory: [] }) {
    this.id = param.id || null;
    this.type = param.type || '';
    this.name = param.name || '';
    this.fullPath = param.fullPath || '';
    this.mime = param.mime || '';
    this.teacherVideoViewingHistory = param.teacherVideoViewingHistory.map((r) => new TeacherVideoViewingHistory(r));
    this.frontIconName = this.getFrontIconName();
  }
  getFrontIconName() {
    switch (this.type) {
      case 'folder':
        return 'faFolder';
      case 'movie':
        return 'faFilm';
      case 'textbook':
        return 'faBook';
      default:
        return 'faFile';
    }
  }
}

class TeacherVideoViewingHistory {
  constructor(param = {}) {
    this.id = param.id || '';
    this.viewingTime = param.viewingTime || null;
    this.isWatchTillEnd = param.isWatchTillEnd || false;
  }
}
export default GuidanceMaterial;
