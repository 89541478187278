import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { me, updateProfile } from '@/api/index';
import Layout from '@/layout/Layout';
import Teacher from '@/entity/Teacher';
import { gender, prefecture } from '@/util/constants';
import { Btn, FDate, FNum, FSel, FTxt, Ic } from '@/components/basic/index';
import { FItemGroup } from '@/components/shared/index';
import style from './Profile.scss';
import TemporaryFile from '@/entity/File';
import { getFileContent, uploadTemporaryFile } from '@/api/file';

const Edit = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [profileFile, setProfileFile] = useState(new TemporaryFile({}));
  const [resumeFile, setResumeFile] = useState(new TemporaryFile({}));
  const [isLoading, setLoading] = useState(false);
  const profileImageRef = useRef(null);
  const resumeFileRef = useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [resumeImage, setResumeImage] = useState(null);

  useEffect(() => {
    (async () => {
      const myProfile = await me();
      setProfile(myProfile);
      const profileFile = myProfile.profileFile();
      if (profileFile) {
        setProfileImage(await contentsOf({ id: profileFile.id }));
      }
      const resumeFile = myProfile.resumeFile();
      if (resumeFile) {
        setResumeImage(await contentsOf({ id: resumeFile.id }));
      }
    })();
  }, []);

  const contentsOf = async ({ id }) => {
    const blob = await getFileContent({ id });
    return URL.createObjectURL(blob);
  };

  const onSave = async () => {
    await updateProfile({ teacher: profile.forApi() });
    toast('変更を保存しました。');
    history.push('/profile');
  };
  const onCancel = () => {
    history.push('/profile');
  };

  const update = (newProp = {}) => {
    setProfile(new Teacher({ ...profile.forApi(), ...newProp }));
  };

  const onFileInputChange = (newFile, reference, attribute = '', setFile = () => {}) => {
    setLoading(true);
    if (newFile.target.files == null) return;
    const reader = new FileReader();
    const file = newFile.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const dataURI = reader.result;
      const base64Data = dataURI.split(',')[1] || dataURI;
      const temporaryFile = new TemporaryFile({
        fileName: file.name,
        attribute: attribute,
        mime: file.type,
        data: base64Data,
      });
      setFile(temporaryFile);
      const tempFile = await uploadTemporaryFile({ file: temporaryFile });
      const newTeacher = new Teacher({ ...profile, tmpFiles: [...profile.tmpFiles, tempFile] });
      setProfile(newTeacher);
      setLoading(false);
    };
    if (reference.current) reference.current.value = '';
  };

  const clickFileUpload = (reference) => {
    reference.current.click();
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.editWrapper}>
          <Form noValidate>
            {profile && (
              <>
                <Row className={style.header}>
                  <Col xs={2}></Col>
                  <Col xs={8} className={style.pageTitle}>
                    個人設定編集
                  </Col>
                  <Col xs={2}></Col>
                </Row>
                <FItemGroup label="氏名" separate full={false}>
                  <Col xs={6}>
                    <FTxt
                      prefix="姓"
                      error=""
                      value={profile.familyName}
                      change={(e) => update({ familyName: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="名"
                      error=""
                      value={profile.firstName}
                      change={(e) => update({ firstName: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup label="カナ" separate full={false}>
                  <Col xs={6}>
                    <FTxt
                      prefix="セイ"
                      error=""
                      value={profile.familyNameKana}
                      change={(e) => update({ familyNameKana: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="メイ"
                      error=""
                      value={profile.firstNameKana}
                      change={(e) => update({ firstNameKana: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup label="郵便番号" separate full={false}>
                  <Col xs={3} className={style.zip1Col}>
                    <FNum value={profile.zip1} error="" change={(e) => update({ zip1: e.target.value })} />
                  </Col>
                  <Col xs={1} className={style.zipSeparateCol}>
                    －
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <FNum value={profile.zip2} error="" change={(e) => update({ zip2: e.target.value })} />
                  </Col>
                </FItemGroup>
                <FItemGroup label="住所" separate full={false}>
                  <div className={style.subItemLabel}>都道府県</div>
                  <div>
                    <FSel
                      value={profile.prefecture}
                      error=""
                      change={(e) => update({ prefecture: e.target.value })}
                      options={prefecture.map((r) => {
                        return { value: r, label: r };
                      })}
                    />
                  </div>
                  <div className={style.subItemLabel}>市区町村番地</div>
                  <div>
                    <FTxt value={profile.address1} error="" change={(e) => update({ address1: e.target.value })} />
                  </div>
                  <div className={style.subItemLabel}>アパートマンション</div>
                  <div>
                    <FTxt value={profile.address2} error="" change={(e) => update({ address2: e.target.value })} />
                  </div>
                </FItemGroup>
                <FItemGroup label="性別" separate>
                  <FSel
                    value={profile.gender}
                    error=""
                    change={(e) => update({ gender: e.target.value })}
                    options={gender.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label="生年月日" separate>
                  <FDate value={profile.birthDate} error="" change={(e) => update({ birthDate: e.target.value })} />
                </FItemGroup>
                <FItemGroup label="TEL" separate full={false}>
                  <Row style={{ marginRight: 0, paddingRight: 0 }}>
                    <Col xs={1} style={{ textAlign: 'center', paddingRight: 0 }}>
                      <Ic iName="faHouse" />
                    </Col>
                    <Col xs={11} style={{ marginRight: 0, paddingRight: 0 }}>
                      <FTxt value={profile.tel} error="" change={(e) => update({ tel: e.target.value })} />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: 0, paddingRight: 0, marginTop: '0.5rem' }}>
                    <Col xs={1} style={{ marginTop: '0.3rem', textAlign: 'center', paddingRight: 0 }}>
                      <Ic iName="faMobileScreenButton" />
                    </Col>
                    <Col xs={11} style={{ marginRight: 0, paddingRight: 0 }}>
                      <FTxt value={profile.mobileTel} error="" change={(e) => update({ mobileTel: e.target.value })} />
                    </Col>
                  </Row>
                </FItemGroup>
                <FItemGroup label={'配信用主メール'} separate>
                  {profile.mail}
                </FItemGroup>
                <FItemGroup label={'大学名'} separate>
                  <FTxt value={profile.university} error="" change={(e) => update({ university: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'学部'} separate>
                  <FTxt value={profile.major} error="" change={(e) => update({ major: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'学年'} separate>
                  <FTxt value={profile.schoolYear} error="" change={(e) => update({ schoolYear: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'自宅最寄り駅'} separate>
                  <FTxt value={profile.station} error="" change={(e) => update({ station: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'出身高校'} separate>
                  <FTxt value={profile.highSchool} error="" change={(e) => update({ highSchool: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'帰省先郵便番号'} separate full={false}>
                  <Col xs={3} className={style.zip1Col}>
                    <FNum value={profile.zip1Family} error="" change={(e) => update({ zip1Family: e.target.value })} />
                  </Col>
                  <Col xs={1} className={style.zipSeparateCol}>
                    －
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <FNum value={profile.zip2Family} error="" change={(e) => update({ zip2Family: e.target.value })} />
                  </Col>
                </FItemGroup>
                <FItemGroup label={'帰省先'} separate full={false}>
                  <div className={style.subItemLabel}>都道府県</div>
                  <div>
                    <FSel
                      value={profile.prefectureFamily}
                      error=""
                      change={(e) => update({ prefectureFamily: e.target.value })}
                      options={prefecture.map((r) => {
                        return { value: r, label: r };
                      })}
                    />
                  </div>
                  <div className={style.subItemLabel}>市区町村番地</div>
                  <div>
                    <FTxt
                      value={profile.address1Family}
                      error=""
                      change={(e) => update({ address1Family: e.target.value })}
                    />
                  </div>
                  <div className={style.subItemLabel}>アパートマンション</div>
                  <div>
                    <FTxt
                      value={profile.address2Family}
                      error=""
                      change={(e) => update({ address2Family: e.target.value })}
                    />
                  </div>
                </FItemGroup>
                <FItemGroup label={'帰省先電話番号'} separate>
                  <FTxt value={profile.telFamily} error="" change={(e) => update({ telFamily: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'受験経験'} separate>
                  <FTxt
                    value={profile.examExperience}
                    error=""
                    change={(e) => update({ examExperience: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導経験'} separate>
                  <FTxt
                    value={profile.teachExperience}
                    error=""
                    change={(e) => update({ teachExperience: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'アレルギー'} separate>
                  <FTxt value={profile.allergy} error="" change={(e) => update({ allergy: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'指導曜日'} separate>
                  <FTxt value={profile.teachDays} error="" change={(e) => update({ teachDays: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'顔写真'} separate>
                  {profileImage && <img className={style.image} alt={'顔写真'} src={profileImage} />}
                  {!profileImage && profileFile.data === '' && (
                    <>
                      <input
                        id="profileImage"
                        type="file"
                        hidden
                        ref={profileImageRef}
                        onChange={(e) => onFileInputChange(e, profileImageRef, 'profile', setProfileFile)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(profileImageRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '読み込み中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                  {!profileImage && profileFile.data && (
                    <>
                      <img className={style.image} alt={'顔写真'} src={profileFile.dataForIMG()} />
                    </>
                  )}
                </FItemGroup>
                <FItemGroup label={'経歴書'} separate>
                  {resumeImage && <img className={style.image} alt={'顔写真'} src={resumeImage} />}
                  {!resumeImage && resumeFile.data === '' && (
                    <>
                      <input
                        id="resumeFile"
                        type="file"
                        hidden
                        ref={resumeFileRef}
                        onChange={(e) => onFileInputChange(e, resumeFileRef, 'resume', setResumeFile)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(resumeFileRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '読み込み中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                  {!resumeImage && resumeFile.data && (
                    <>
                      <img className={style.image} alt={'経歴書'} src={resumeFile.dataForIMG()} />
                    </>
                  )}
                </FItemGroup>
                <Row className={style.footer}>
                  <Col>
                    <Btn prefixIcon="faFloppyDisk" click={async () => await onSave()} showLoading={true}>
                      保存
                    </Btn>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <Btn prefixIcon="faXmark" outline color="secondary" click={() => onCancel()}>
                      キャンセル
                    </Btn>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Container>
      </Layout>
    </>
  );
};

export default Edit;
