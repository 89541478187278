import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const DateFormat = ({ format = 'YYYY年MM月DD日', date = '' }) => {
  const day = dayjs(date);
  const formatted = day.isValid() ? day.format(format) : '';
  return <>{formatted}</>;
};

DateFormat.propTypes = {
  format: PropTypes.string,
  date: PropTypes.string,
};

DateFormat.defaultProps = {
  format: 'YYYY年MM月DD日',
};

export default DateFormat;
