import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { getRecords, addRecord, getLatestRecord, getRecordImage } from '@/api/index';
import { FTxt, FDate, Btn, Ic } from '@/components/basic/index';
import { FItemGroup, Toast, DateFormat } from '@/components/shared/index';
import style from '@/pages/report/Report.scss';
import { Record, RecordSubject } from '@/entity/Report';
import { Validator, Rule } from '@/util/validate';
import ImageModal from './ImageModal';
import EditModal from './EditModal';

const Records = ({ studentId, showModal, onShow = () => {}, onClose = () => {} }) => {
  const [image, setImage] = useState({ show: false, file: null, title: null });
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState(new Record());
  const [errors, setErrors] = useState({});

  const fillRecords = async (id) => {
    const data = await getRecords(id);
    setRecords(data);
  };

  const setLatestRecordSubjects = async (id) => {
    const d = await getLatestRecord(id);
    if (!d) return;
    setRecord(() => new Record(d, true));
  };

  const showImage = async (recordId, imageId, name, mime) => {
    const data = await getRecordImage(studentId, recordId, imageId);
    const reader = new FileReader();
    reader.onload = () => {
      const b64 = reader.result;
      setImage({ ...image, show: true, file: b64.replace('application/octet-stream', mime), title: name });
    };
    reader.readAsDataURL(data);
  };
  const closeImage = () => setImage({ ...image, show: false, file: null, title: null });

  const validate = () => {
    const validate = new Validator(record);
    validate.exec('date', 'テスト実施日', [Rule.require()]);
    validate.exec('maxPoints', '最大評価', [Rule.require()]);
    record.recordSubjects.forEach((_, i) => {
      validate.exec(`recordSubjects[${i}].subjectName`, '科目名', [Rule.require()]);
      validate.exec(`recordSubjects[${i}].points`, '評価', [Rule.require()]);
    });
    setErrors(validate.getErrors());
    return validate.inValid();
  };

  const storeRecord = async () => {
    if (validate()) return;
    try {
      await addRecord(studentId, record);
      await fillRecords(studentId);
      Toast.success('成績を追加しました');
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const newRowRecordSubject = () => {
    record.recordSubjects.push(new RecordSubject());
    setRecord({ ...record });
  };
  const removeRowRecordSubject = (idx) =>
    setRecord({ ...record, recordSubjects: record.recordSubjects.filter((_, i) => i !== idx) });

  useEffect(() => {
    fillRecords(studentId);
  }, []);

  useEffect(async () => {
    if (showModal) {
      setErrors({});
      setRecord(new Record());
      await setLatestRecordSubjects(studentId);
    }
  }, [showModal]);
  return (
    <Container fluid>
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col style={{ textAlign: 'right' }}>
          <Btn children="新しい成績" click={onShow} color="success" prefixIcon="faPlus" />
        </Col>
      </Row>
      {records.map((r, i) => {
        return (
          <div key={i + 'grade-n'}>
            <Card className={[style.card]}>
              <Card.Title>
                <DateFormat date={r.date} />
              </Card.Title>
              <Card.Body>
                <table className={style.gradeTable}>
                  <tbody>
                    {r.recordSubjects.map((rr, ii) => {
                      return (
                        <React.Fragment key={ii + 'record'}>
                          <tr>
                            <td className={style.subject}>{rr.subjectName}</td>
                            <td>
                              <span style={{ fontSize: '1.3rem', border: 'none' }}>{rr.points}</span>
                              <span style={{ fontSize: '0.5rem', border: 'none', marginRight: '0.2rem' }}>
                                /{r.maxPoints}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                {r.recordImages.map((rr, ii) => {
                  return (
                    <React.Fragment key={ii + 'record'}>
                      <div className={style.imageLink}>
                        <a onClick={() => showImage(r.id, rr.id, rr.name, rr.mime)}>
                          <Ic iName="faImage" />
                          {rr.name}
                        </a>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Card.Body>
            </Card>
          </div>
        );
      })}
      <ImageModal show={image.show} title={image.title} file={image.file} onClose={closeImage} />
      <EditModal
        show={showModal}
        title="成績"
        onClose={onClose}
        onRegister={storeRecord}
        registerBtnDisabled={record.recordSubjects.length === 0}
      >
        <FItemGroup label="成績日" className={style.spaceB} errors={errors} paths={['date']}>
          <FDate value={record.date} change={(e) => setRecord({ ...record, date: e.target.value })} />
        </FItemGroup>
        <FItemGroup label="成績段階" className={style.spaceB} errors={errors} paths={['maxPoints']}>
          <Col xs={5}>
            <FTxt
              suffix="段階評価"
              value={record.maxPoints}
              change={(e) => setRecord({ ...record, maxPoints: e.target.value })}
            />
          </Col>
        </FItemGroup>
        {record.recordSubjects.map((r, i) => {
          return (
            <React.Fragment key={i.toString()}>
              <FItemGroup
                label="科目"
                className={style.spaceB}
                errors={errors}
                paths={[`recordSubjects[${i}].subjectName`, `recordSubjects[${i}].points`]}
              >
                <Row>
                  <Col xs={10}>
                    <FTxt
                      value={r.subjectName}
                      style={{ marginBottom: '0.2rem' }}
                      change={(e) => {
                        record.recordSubjects[i].subjectName = e.target.value;
                        setRecord({ ...record, recordSubjects: record.recordSubjects });
                      }}
                    />
                  </Col>
                  <Col xs={1}>
                    <Btn
                      isCustom
                      outline
                      prefixIcon="faXmark"
                      click={() => removeRowRecordSubject(i)}
                      width="30px"
                      height="30px"
                      className={style.xmarkButton}
                    />
                  </Col>
                </Row>
                <Col xs={1} className={style.spaceB}></Col>
                <Row>
                  <Col xs={5}>
                    <FTxt
                      suffix="段階"
                      value={r.points}
                      change={(e) => {
                        record.recordSubjects[i].points = e.target.value;
                        setRecord({ ...record, recordSubjects: record.recordSubjects });
                      }}
                    />
                  </Col>
                </Row>
              </FItemGroup>
            </React.Fragment>
          );
        })}
        <Btn outline prefixIcon="faPlus" click={newRowRecordSubject} />
      </EditModal>
    </Container>
  );
};

Records.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showModal: PropTypes.bool,
  onShow: PropTypes.func,
  onClose: PropTypes.func,
};
export default Records;
