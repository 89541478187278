import React, { useEffect, useState } from 'react';
import { getReports } from '@/api/index';
import style from '@/pages/report/Report.scss';
import { DateFormat, Section } from '@/components/shared/index';
import { Btn } from '@/components/basic/index';
import { Row, Col, Container } from 'react-bootstrap';

const ReportList = ({ studentId, toReportDetail, toNew }) => {
  const [reports, setReports] = useState([]);
  useEffect(async () => {
    const _reports = await getReports(studentId);
    setReports(_reports);
  }, []);
  const status = (report) => {
    if (report.settlement && report.settlement.isPaymented) {
      return { label: '清算済', class: style.paymented };
    }
    if (report.isApproval) {
      return { label: '承認済み', class: style.approved };
    }
    return report.isTurnIn ? { label: '提出済', class: style.turnIn } : { label: '未提出', class: style.notTurnIn };
  };
  return (
    <Container fluid className={style.listWrapper}>
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col>
          <Btn children="新しい報告書" click={toNew} color="success" prefixIcon="faPlus" />
        </Col>
      </Row>
      <Section title="指導報告書一覧">
        {reports.map((report, i) => {
          return (
            <div className={status(report).class} key={i} onClick={() => toReportDetail(report.id)}>
              <div className={style.status}>{status(report).label}</div>
              <div className={style.date}>
                <span>指導日</span>
                <DateFormat date={report.date} />
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          );
        })}
      </Section>
    </Container>
  );
};

export default ReportList;
