import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import cssStyle from './Shared.scss';
const DspItemGroup = ({ label, children, bottomBorder }) => {
  const Label = (v) => {
    return (v || '').split('\n').map((r, i) => {
      return (
        <React.Fragment key={i}>
          {r} <br />
        </React.Fragment>
      );
    });
  };
  return (
    <>
      <Row>
        <Col xs={3} className={cssStyle.dspLabel}>
          {Label(label)}
        </Col>
        <Col xs={9} className={cssStyle.dspValue}>
          {children}
        </Col>
      </Row>
      {bottomBorder ? <div className={cssStyle.bottomBorder} /> : <></>}
    </>
  );
};

DspItemGroup.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  bottomBorder: PropTypes.bool,
};

DspItemGroup.defaultProps = {
  bottomBorder: true,
};

export default DspItemGroup;
