import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '@/pages/Login';
import Password from '@/pages/Password';
import Reset from '@/pages/Reset';
import { default as EditProfile } from '@/pages/profile/Edit';
import VerifyReset from '@/pages/VefifyReset';
import Profile from '@/pages/profile/Profile';
import Matter from '@/pages/matter/Matter';
import Students from '@/pages/student/Students';
import Karte from '@/pages/student/Karte';
import CoachingDocuments from '@/pages/coachingDocument/CoachingDocuments';
import Document from '@/pages/coachingDocument/CoachingMovie';
// import Report from '@/pages/report/Report';
import Report from '@/pages/report/Report';

export const isNoNeedLogins = (location) => {
  return (
    ['/login', '/password', '/verifyReset', '/reset'].filter((l) => {
      return l === location;
    }).length > 0
  );
};

const routes = (
  <div>
    <Switch>
      <Route exact path="/" component={Students} />
      <Route path="/login" component={Login} />
      <Route path="/password" component={Password} />
      <Route path="/verifyReset/:resetToken" component={VerifyReset} />
      <Route path="/reset" component={Reset} />
      <Route path="/profile/edit" component={EditProfile} />
      <Route path="/profile" component={Profile} />
      <Route path="/matter" component={Matter} />
      {/* <Route path="/student/:id/report/:reportId" component={ReportEdit} /> */}
      <Route path="/student/:id/report" component={Report} />
      <Route path="/student/:id/karte" component={Karte} />
      <Route path="/student" component={Students} />
      <Route path="/coaching-document" component={CoachingDocuments} />
      <Route path="/coaching-movie/:id" component={Document} />
    </Switch>
  </div>
);

export default routes;
