import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { verifyResetToken } from '@/api/index';
import style from './Login.scss';

const VerifyReset = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const { resetToken } = useParams();

  useEffect(() => {
    (async () => {
      const verify = await verifyResetToken({ resetToken });
      setValidated(true);
      if (verify === 'ok') {
        history.push({
          pathname: '/reset',
          state: { resetToken },
        });
      } else {
        setShowInvalidMessage(true);
      }
    })();
  }, [resetToken]);

  return (
    <>
      {validated && showInvalidMessage && (
        <>
          <Container fluid className={style.loginWrapper}>
            <div className={style.contents}>
              <div>
                <div className={style.form}>
                  <div>リンクの有効期限が切れました。</div>
                  <div>メール送信から再度操作を行ってください。</div>
                  <div>
                    <a className={style.link} onClick={() => history.push('/password')}>
                      メール再送信
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default VerifyReset;
