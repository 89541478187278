import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { getStudent } from '@/api/index';
import Student from '@/entity/Student';
import Layout from '@/layout/Layout';
import { Ic } from '@/components/basic/index';
import style from './Report.scss';
import Exams from '@/components/page-parts/report-edit/Exams';
import Records from '@/components/page-parts/report-edit/Records';
import Settlements from '@/components/page-parts/report-edit/Settlements';
import ReportEditor from '@/components/page-parts/report-edit/ReportEditor';
import ReportList from '@/components/page-parts/report-edit/ReportList';

const ReportEdit = () => {
  const [student, setStudent] = useState(new Student({}));
  const [showEditor, setShowEditor] = useState(false);
  const [showExam, setShowExam] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [selectReportId, setSelectReportId] = useState(null);

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const handleCloseExam = () => setShowExam(false);
  const handleShowExam = () => setShowExam(true);
  const handleCloseRecord = () => setShowRecord(false);
  const handleShowRecord = () => setShowRecord(true);

  useEffect(async () => {
    const _student = await getStudent({ id });
    setStudent(_student);
    setLoading(true);
  }, []);

  const toReportDetail = (reportId) => {
    setSelectReportId(reportId);
    setShowEditor(true);
  };
  const toNew = () => {
    setSelectReportId(null);
    setShowEditor(true);
  };
  const close = () => {
    setShowEditor(false);
  };
  const ListOrEditor = () => {
    return showEditor ? (
      <ReportEditor
        studentId={student.id}
        reportId={selectReportId}
        showExamModalFunc={handleShowExam}
        showRecordModalFunc={handleShowRecord}
        close={close}
      />
    ) : (
      <ReportList studentId={student.id} toReportDetail={toReportDetail} toNew={toNew} />
    );
  };

  return (
    <>
      <Layout>
        <div className={style.editFormWrapper}>
          <div className={style.studentPanel}>
            <div className={style.studentName}>
              {student.familyName}
              {student.firstName}
            </div>
          </div>
          <Tabs defaultActiveKey="report" fill className="report-tabs">
            <Tab
              eventKey="report"
              title={
                <>
                  <div className={style.tabIconHoukoku}>
                    <Ic iName="faFileLines" />
                  </div>
                  <div className={style.tabTitle}>指導報告</div>
                </>
              }
            >
              {loading ? <ListOrEditor /> : <></>}
            </Tab>
            <Tab
              eventKey="test"
              title={
                <>
                  <div className={style.tabIconTest}>
                    <Ic iName="faPen" />
                  </div>
                  <div className={style.tabTitle}>テスト</div>
                </>
              }
            >
              {loading ? (
                <Exams studentId={student.id} showModal={showExam} onShow={handleShowExam} onClose={handleCloseExam} />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              eventKey="grade"
              title={
                <>
                  <div className={style.tabIconSeiseki}>
                    <Ic iName="faChartLine" />
                  </div>
                  <div className={style.tabTitle}>成績</div>
                </>
              }
            >
              {loading ? (
                <Records
                  studentId={student.id}
                  showModal={showRecord}
                  onShow={handleShowRecord}
                  onClose={handleCloseRecord}
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              eventKey="closing"
              title={
                <>
                  <div className={style.tabIconShime}>
                    <Ic iName="faYenSign" />
                  </div>
                  <div className={style.tabTitle}>精算</div>
                </>
              }
            >
              {loading ? <Settlements familyId={student.family.id} /> : <></>}
            </Tab>
          </Tabs>
        </div>
      </Layout>
    </>
  );
};

export default ReportEdit;
