import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Btn } from '@/components/basic/index';
const EditModal = ({
  title,
  show = false,
  children,
  onClose = () => {},
  onRegister = () => {},
  registerBtnDisabled,
}) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Btn click={onRegister} showLoading={true} disabled={registerBtnDisabled}>
          登録
        </Btn>
      </Modal.Footer>
    </Modal>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onRegister: PropTypes.func,
  children: PropTypes.node,
  registerBtnDisabled: PropTypes.bool,
};

EditModal.defaultProps = {
  registerBtnDisabled: false,
};

export default EditModal;
