import PropTypes from 'prop-types';
import cssStyle from './Shared.scss';
const Section = ({ first = false, title, children }) => {
  return (
    <>
      <div className="section-title" style={{ marginTop: first ? '0px' : '2rem' }}>
        <div className={cssStyle.sectionBorder} />
        <div className={cssStyle.sectionTitleWrapper}>
          <span className={cssStyle.title}>{title}</span>
        </div>
        <div className={cssStyle.sectionChildrenWrapper}>{children}</div>
      </div>
    </>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  first: PropTypes.bool,
};

Section.defaultProps = {
  first: false,
};

export default Section;
